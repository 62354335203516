<template>
  <div id="index" ref="appRef" class="bg">
    <div class="bg">
      <div style="position: absolute; right: 15px;">
        <el-button link @click="changeLang('zh')">中文</el-button>
        <el-button link @click="changeLang('en')">English</el-button>
        <el-button link @click="changeLang('fr')">français</el-button>
      </div>
      <div class="loading" v-if="loading">
        <dv-loading>Loading...</dv-loading>
      </div>
      <div class="host-body">
        <NavHeader @edit-user="changeVisibility('user')"
                   @change-password="changeVisibility('password')"
                   @change-settings="changeVisibility('settings')"/>
        <div class="body-box">
          <router-view/>
        </div>
        <div class="bottom-box">
          <CenterFooter/>
        </div>
        <el-dialog v-model="userDialogVisible" :title="t('dashboard.userDialogTitle')">
          <user-form @close-user-form="userDialogVisible=false" :editBasicInfo="!passwordDialogVisible"/>
        </el-dialog>
        <el-dialog v-model="settingsDialogVisible" :title="t('dashboard.settingsDialogTitle')">
          <settings-form @close-settings-form="settingsDialogVisible=false"/>
        </el-dialog>
      </div>
    </div>
  </div>
</template>


<script setup>

import NavHeader from "./NavHeader.vue"
//import CenterView from "./View.vue"
import CenterFooter from "./Footer.vue"
import UserForm from "./UserForm.vue"
import SettingsForm from "./SettingsForm.vue"

import {useStore} from 'vuex'
import {useRoute} from 'vue-router'

import {
  ref,
  onMounted,
  onBeforeUnmount,
  watch,
} from 'vue'

import useDraw from '@/utils/useDraw'
import {useI18n} from "vue-i18n";

const {t} = useI18n()
const store = useStore()
const route = useRoute()

const userDialogVisible = ref(false)
const passwordDialogVisible = ref(false)
const settingsDialogVisible = ref(false)

const loading = ref(true)

const timerId = ref()

const {appRef, calcRate, windowDraw, unWindowDraw} = useDraw()

const setTimer = function () {
  if (route.path.startsWith('/layout/managecenter')) {
    return
  }
  timerId.value = setInterval(() => {
    // store.dispatch("models/request", true);
    store.dispatch("companies/request", true);
    store.dispatch('cables/request', true);
  }, 1000 * 60)
}

setTimer()

onMounted(async () => {
  // await store.dispatch("models/request")
  await store.dispatch("companies/request")
  await store.dispatch('cables/request')
      .then((r) => {
        // cancelLoading()
        windowDraw()
        calcRate()
      })

})

onBeforeUnmount(() => {
  unWindowDraw()
})

const cancelLoading = () => {
  setTimeout(() => {
    loading.value = false
  }, 100)
}

setTimeout(() => {
  loading.value = false
}, 1000 * 10)

watch(() => store.state.cables.actionStatus.all,
    (v) => {
      if (v > 1) {
        cancelLoading()
      }
    })

watch(() => route.path,
    (v) => {
      clearInterval(timerId.value)
      timerId.value = -1
      if (v.startsWith('/layout/managecenter')) {
        timerId.value = -1
      } else {
        setTimer()
      }
    })

function changeLang(lang) {
  store.commit('user/ChangeLang', lang)
  location.reload()
}

function changeVisibility(item) {
  userDialogVisible.value = false
  passwordDialogVisible.value = false
  settingsDialogVisible.value = false
  if (item === 'user') {
    userDialogVisible.value = true
  } else if (item === 'password') {
    userDialogVisible.value = true
    passwordDialogVisible.value = true
  } else if (item === 'settings') {
    settingsDialogVisible.value = true
  }
}

</script>

<style lang="scss">
@import '@/assets/scss/index.scss';

.el-dialog {
  width: 500px
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 1080px;
  width: 1920px;
  background-color: #0f1325;
  opacity: 0.5;
  z-index: 10000;
}
</style>
