

<template>
    <div>
        <h1></h1>
    </div>
</template>

<script type='ts'>
import { defineComponent,  } from 'vue'
export default defineComponent({
  setup() {
    
    return { 

    }
  }
})
</script>

<style lang="scss" scoped>
//@import '@/assets/scss/index.scss';
</style>
