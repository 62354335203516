<template>
  <el-form label-width="140px">
    <el-form-item :label="t('dashboard.useNonMapMode')">
      <el-switch v-model="nonMapMode"/>
    </el-form-item>
    <el-form-item :label="t('dashboard.useCustomPlatformName')">
      <el-switch v-model="useCustomPlatformName"/>
    </el-form-item>
    <el-form-item :label="t('dashboard.customPlatformName')">
      <el-input :disabled="!useCustomPlatformName" v-model.trim="customPlatformName"/>
    </el-form-item>
    <el-form-item>
      <el-button @click="confirm">{{ t('dashboard.confirm') }}</el-button>
      <el-button @click="close">{{ t('dashboard.cancel') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import {ref, defineEmits} from 'vue'
import {useStore} from 'vuex'
import {setUserInfo} from "@/api/user"
import {renewCompany} from "@/api/company"
import {ElMessage} from "element-plus"
import {useI18n} from "vue-i18n"


const {t} = useI18n()

const store = useStore()

const emit = defineEmits(['close-settings-form'])

const nonMapMode = ref(store.state.user.userInfo.nonMapMode)

const company = store.getters["companies/getById"](store.state.user.userInfo.devCompanyId)

const useCustomPlatformName = ref(company.useCustomPlatformName)
const customPlatformName = ref(company.customPlatformName)

async function confirm() {
  let requests = [
    setUserInfo({
      id: store.state.user.userInfo.id,
      nonMapMode: nonMapMode.value,
    }),
    renewCompany({
      ...company,
      useCustomPlatformName: useCustomPlatformName.value,
      customPlatformName: customPlatformName.value,
    })
  ]
  await Promise.all(requests)
  ElMessage.success('设置成功')
  setTimeout(() => {
    location.reload()
  }, 1000)
}

function close() {
  nonMapMode.value = store.state.user.userInfo.nonMapMode
  useCustomPlatformName.value = company.useCustomPlatformName
  customPlatformName.value = company.customPlatformName
  emit('close-settings-form')
}

</script>

<style scoped>

</style>
