<template>
  <el-form
      label-width="140px"
      v-if="props.editBasicInfo"
  >
    <el-form-item :label="t('dashboard.username')">
      {{ userInfo.userName }}
    </el-form-item>
    <el-form-item :label="t('dashboard.company')">
      {{ userInfo.companyName }}
    </el-form-item>
    <el-form-item :label="t('dashboard.nickname')">
      <EditableItem :dataId="userInfo.id" fieldName="nickName" :fieldValue="userInfo.nickName"/>
    </el-form-item>
    <el-form-item :label="t('dashboard.phone')">
      <EditableItem :dataId="userInfo.id" fieldName="mobilePhone" :fieldValue="userInfo.mobilePhone"/>
    </el-form-item>
    <el-form-item :label="t('dashboard.email')">
      <EditableItem :dataId="userInfo.id" fieldName="email" :fieldValue="userInfo.email"/>
    </el-form-item>
<!--    <el-button class="pswd-btn" type="danger" @click="editTabVisible=false">修改密码</el-button>-->
  </el-form>

  <el-form ref="pwdFormRef" :model="pwdForm" :rules="pwdRules" label-position="top" v-else>
    <el-form-item :label="t('dashboard.password')" prop="password">
      <el-input v-model="pwdForm.password" type="password" autocomplete="off" show-password/>
    </el-form-item>
    <el-form-item :label="t('dashboard.newPassword')" prop="newPassword">
      <el-input v-model="pwdForm.newPassword" type="password" autocomplete="off" show-password/>
    </el-form-item>
    <el-form-item :label="t('dashboard.checkPass')" prop="checkPass">
      <el-input v-model="pwdForm.checkPass" type="password" autocomplete="off" show-password/>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitChangePwd(pwdFormRef, pwdForm)">{{ t('dashboard.confirm') }}</el-button>
      <el-button @click="$emit('close-user-form')">{{ t('dashboard.cancel') }}</el-button>
    </el-form-item>
  </el-form>

</template>


<script setup>
import EditableItem from './EditableItem.vue'
import {useStore} from 'vuex'
import {
  ref,
  reactive,
  defineEmits,
  defineProps,
} from 'vue'
import {
  changePassword
} from '@/api/user.js'
import {ElMessage} from 'element-plus'
import {useI18n} from "vue-i18n";

const { t } = useI18n()

const emit = defineEmits(['close-user-form'])

// const editTabVisible = ref(true)

const props = defineProps(["editBasicInfo"])

const store = useStore()
const userInfo = reactive({
  id: store.getters['user/userInfo'].id,
  userName: store.getters['user/userInfo'].userName,
  nickName: store.getters['user/userInfo'].nickName,
  companyName: store.getters['user/userInfo'].companyName,
  mobilePhone: store.getters['user/userInfo'].mobilePhone,
  email: store.getters['user/userInfo'].email,
  headerImg: store.getters['user/userInfo'].headerImg,
})

// console.log('store.getters[\'user/userInfo\']', store.getters['user/userInfo'])

const pwdFormRef = ref()

const pwdForm = reactive({
  password: '',
  newPassword: '',
  checkPass: '',
})

const validatePass = (rule, value, callback) => {
  if (pwdForm.checkPass !== '') {
    if (!pwdFormRef.value) return
    pwdFormRef.value.validateField('checkPass', () => null)
  }
  callback()
}

const validatePass2 = (rule, value, callback) => {
  if (value !== pwdForm.newPassword) {
    callback(new Error(t('dashboard.validatePasswordNotIdentical')))
  } else {
    callback()
  }
}

const pwdRules = reactive({
  password: [
    {required: true, message: t('dashboard.validatePassword'), trigger: 'blur'},
  ],
  newPassword: [
    {required: true, message: t('dashboard.validateNewPassword'), trigger: 'blur'},
    {validator: validatePass, trigger: 'blur'},
  ],
  checkPass: [
    {required: true, message: t('dashboard.validateCheckPass'), trigger: 'blur'},
    {validator: validatePass2, trigger: 'blur'},
  ],
})

const submitChangePwd = function (formEl, data) {
  if (!formEl) return
  formEl.validate(async (valid) => {
    if (valid) {
      // console.log('submit!')
      const r = await changePassword({
        username: userInfo.userName,
        password: data.password,
        newPassword: data.newPassword,
      })
      if (r.code === 0) {
        ElMessage({
          type: 'success',
          message: t('dashboard.messagePasswordChangeSuccess')
        })
      emit('close-user-form')
      } else {
        ElMessage({
          type: 'error',
          message: t('dashboard.messagePasswordChangeFailure')
        })
      }
    } else {
      // console.log('error submit!')
      return false
    }
  })
}


</script>

<style>
.pswd-btn {
  position: absolute;
  right: 50px;
  top: 80px;
}

</style>
