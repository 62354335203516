<template>
  <div>
    <div style="display: flex; flex-direction: row; justify-content: space-around">
      <template v-for="item in asyncRouters[0].children">
        <div  v-if="!item.hidden" :key="item.name" @click="toTarget(item.name)">
          <div>
            <el-button size="large">
              <el-icon>
                <component :is="item.meta.icon" />
              </el-icon>
              <span>{{ this.$store.state.user.lang === 'zh' ?
                  item.meta.title :
                  this.t('dbMenus.' + item.meta.title)
                }}</span>
            </el-button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { emitter } from '@/utils/bus.js'
import { useI18n } from "vue-i18n";

export default {
  name: 'Aside',
  components: {
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      active: '',

      //      isCollapse: false
    }
  },
  computed: {
    ...mapGetters('router', ['asyncRouters']),
    //    ...mapGetters('user', ['baseColor', 'activeColor', 'sideMode'])
  },
  watch: {
    $route() {
      this.active = this.$route.name
    }
  },
  created() {
    this.active = this.$route.name
    /*
    const screenWidth = document.body.clientWidth
    if (screenWidth < 1000) {
      this.isCollapse = !this.isCollapse
    }

    emitter.on('collapse', item => {
      this.isCollapse = item
    })
    */
  },
  beforeUnmount() {
    emitter.off('collapse')
  },
  methods: {
    //  ...mapMutations('history', ['addHistory']),
    toTarget(name) {
      if (name === this.$route.name) return
      this.$router.push({ name })
    },
    selectMenuItem(index, _, ele) {
      const query = {}
      const params = {}
      ele?.route?.parameters &&
        ele.route.parameters.forEach(item => {
          if (item.type === 'query') {
            query[item.key] = item.value
          } else {
            params[item.key] = item.value
          }
        })
      if (index === this.$route.name) return
      if (index.indexOf('http://') > -1 || index.indexOf('https://') > -1) {
        window.open(index)
      } else {
        this.$router.push({ name: index, query, params })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-button {
  height: 50px;
  flex-grow: 1;
  transform: skewX(45deg);
  .el-icon {
    font-size:200%;
    transform: skewX(-45deg);
  }
  span {
    font-size:120%;
    transform: skewX(-45deg);
  }
}
</style>