<template>
  <p v-if="editFlag">
    <el-row>
      <el-col span="18">
    <el-input v-model="editedData"/>
      </el-col>
      <el-col span="3">
    <el-icon size="large" color="#67c23a" @click="enterEdit">
      <check/>
    </el-icon>
      </el-col>
      <el-col span="3">
    <el-icon size="large" color="#f23c3c" @click="closeEdit">
      <close/>
    </el-icon>
      </el-col>
    </el-row>
  </p>

  <p v-else> {{ editedData }}
    <el-icon color="#66b1ff" @click="openEdit">
      <edit/>
    </el-icon>
  </p>
</template>

<script setup>
import {
  ref,
  defineProps,
} from 'vue'
import {setUserInfo} from "@/api/user"
import {ElMessage} from 'element-plus'

const editFlag = ref(false)

const props = defineProps(['dataId', 'fieldName', 'fieldValue'])

const editedData = ref(props.fieldValue)

let backup = ''

const openEdit = function () {
  editFlag.value = true
  backup = editedData.value
}

const enterEdit = async function () {
  const data = {id: props.dataId}
  data[props.fieldName] = editedData.value
  const res = await setUserInfo(data)
  if (res.code === 0) {
    ElMessage({
      type: 'success',
      message: '设置成功'
    })
  }
  backup = ''
  editFlag.value = false
}

const closeEdit = function () {
  editedData.value = backup
  backup = ''
  editFlag.value = false
}


</script>
