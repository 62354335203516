<template>
  <div>
    <div class="d-flex jc-center">
      <dv-decoration-10 class="dv-dec-10"/>
      <div class="d-flex jc-center">
        <dv-decoration-8 class="dv-dec-8" :color="['#568aea', '#000000']"/>

        <div class="title">
          <span class="title-text">{{ getTitle() }}</span>
          <dv-decoration-6
              class="dv-dec-6"
              :reverse="true"
              :color="['#50e3c2', '#67a1e5']"
          />
        </div>
        <dv-decoration-8
            class="dv-dec-8"
            :reverse="true"
            :color="['#568aea', '#000000']"
        />
      </div>
      <dv-decoration-10 class="dv-dec-10-s"/>
    </div>

    <!-- 第二行 -->
    <div class="d-flex jc-between">
      <span class="text"> <TitleMenu class="title-menu"/> </span>

      <div class="d-flex aside-width">
        <div class="parallel-right mr-3 bg-color-dark">
          <span class="text">
            {{ timeInfo.dateYear }} {{ timeInfo.dateWeek }}
            {{ timeInfo.dateDay }}
          </span>
        </div>

        <div class="parallel-right mr-4 parallel-l-s">
          <div class="parallel-after bg-color-dark">
            <div class="userinfo">
              <span>{{ $store.getters['user/userInfo'].companyName }}</span>
              &nbsp;
              <span>{{ $store.getters['user/userInfo'].userName }}</span>
              &nbsp;
            </div>
            <el-dropdown size="large" trigger="click">
              <span class="el-dropdown-link">
                <el-icon class="avatar"><UserFilled/></el-icon>

                <!--                <el-avatar class="avatar" :src="$store.getters['user/userInfo'].headerImg" size="default"/>-->
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="$emit('edit-user')">{{
                      this.t('dashboard.dropdownEditUser')
                    }}
                  </el-dropdown-item>
                  <el-dropdown-item @click="$emit('change-password')">{{
                      this.t('dashboard.dropdownChangePassword')
                    }}
                  </el-dropdown-item>
                  <el-dropdown-item @click="$emit('change-settings')">{{
                      this.t('dashboard.dropdownChangeSettings')
                    }}
                  </el-dropdown-item>
                  <el-dropdown-item @click="$store.dispatch('user/LoginOut')">{{
                      this.t('dashboard.dropdownLogOut')
                    }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {formatTime} from '@/utils/index'
import {WEEK} from '@/constant/index'
// import {title} from '@/constant/index'
import TitleMenu from './TitleMenu.vue'
import {emitter} from '@/utils/bus.js'
import {UserFilled} from "@element-plus/icons-vue";
import {useI18n} from 'vue-i18n'

export default {
  components: {
    UserFilled,
    TitleMenu,
  },
  setup() {
    const {t} = useI18n()
    return {t}
  },
  data() {
    return {
      timeInfo: {},
      active: '',
      isCollapse: false,
      title: this.t('dashboard.title'),
    }
  },
  watch: {
    $route() {
      this.active = this.$route.name
    }
  },
  created() {
    this.active = this.$route.name
    this.handleTime()
    emitter.on('collapse', item => {
      this.isCollapse = item
    })
  },
  beforeUnmount() {
    emitter.off('collapse')
    clearInterval(this.timeInfo.setInterval)
  },
  mounted() {
    this.clip()
  },
  methods: {
    handleTime() {
      this.timeInfo.setInterval = setInterval(() => {
        const date = new Date()
        this.timeInfo.dateDay = formatTime(date, 'HH:mm:ss')
        this.timeInfo.dateYear = formatTime(date, 'yyyy-MM-dd')
        this.timeInfo.dateWeek = this.t('weeks.' + WEEK[date.getDay()])
      }, 1000)
    },
    clip() {
      let dvDec8s = document.querySelectorAll('.dv-dec-8')
      for (let d of dvDec8s) {
        d.children[0].removeChild(d.children[0].children[2])
      }
    },
    getTitle() {
      const user = this.$store.state.user
      const company = this.$store.getters["companies/getById"](user.userInfo?.devCompanyId)
      if (company.useCustomPlatformName) {
        return company.customPlatformName
      } else {
        return this.t('dashboard.title')
      }
    },
  },
}

</script>

<style lang="scss" scoped>
.title {
  position: relative;
  width: 450px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;

  .title-text {
    font-size: 26px;
    line-height: 50px;
    left: 30%;
  }

  .dv-dec-6 {
    position: absolute;
    bottom: -30px;
    left: 50%;
    width: 300px;
    height: 8px;
    transform: translate(-50%);
  }
}

.title-menu {
  position: absolute;
  width: 40%;
  height: 80px;
  left: 1%;
}

.dv-dec-10,
.dv-dec-10-s {
  width: 35%;
  height: 5px;
}

.dv-dec-10-s {
  transform: rotateY(180deg);
}

.dv-dec-8 {
  width: 150px;
  height: 50px;
}

.parallel-after {
  font-size: 18px;
  line-height: 30px;
  //padding-top: 10px;
  padding-right: 10px;
  position: absolute;
  right: -25px;
  top: 0;
  height: 50px;
  width: 450px;
  transform: skewX(45deg);

  .userinfo {
    width: 400px;
    padding-top: 10px;
    //padding-right: 10px;
  }

  .el-dropdown {
    font-size: 18px;
    line-height: 30px;
  }

  .avatar {
    //width: 20px;
    //height: 20px;
    position: absolute;
    bottom: 7px;
    right: 10px;
  }
}

</style>
